/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 20/10/2024.
 */
import React from 'react'
import { FormLabel } from './FormLabel'
import { Switch } from 'antd'

export const FormViewItem = ({ item }) => {
  const className = item.className ? item.className : 'col-12'

  if (item.type === 'SPACER') {
    return (
      <div className={item.className}>
        <FormLabel label={item.label}></FormLabel>
        <h5
          className="p-small text-center py-2 rounded-1 "
          style={{ border: '1px solid #D0D0D0', marginTop: item.label ? 0 : 22 }}
        >
          {item.value}
        </h5>
      </div>
    )
  }

  if (item.type === 'SECTION') {
    return (
      <div className="row" style={{}}>
        <div className="col-12 mt-2 ">
          <p className="">
            {item.label} <span className="opacity-75">{item.postfix}</span>
          </p>
          {item.value ? <p className="opacity-75 mb-2">{item.value}</p> : null}
        </div>
      </div>
    )
  }

  return (
    <div className={className + ' mb-2 py-1'}>
      {item.label ? <FormLabel label={item.label}></FormLabel> : <FormLabel label={item.placeholder}></FormLabel>}

      {item?.type === 'TEXT' ? <h5 className="p-small bg-white p-2 mb-0 rounded-1">{item.value}</h5> : null}
      {item?.type === 'NUMBER' ? (
        <div className="">
          <h5 className=" p-small bg-white px-2 py-2 mb-0 rounded-1">{item.value}</h5>
        </div>
      ) : null}

      {item?.type === 'SWITCH' ? (
        <div className="d-flex rounded-2 mt-1">
          <Switch className="" checked={item.value} disabled={true} />
        </div>
      ) : null}

      {item?.type === 'DROPDOWN' ? (
        <div className="d-flex bg-white p-2 rounded-2">
          <p className="pe-1 fw-bold">{item.value}</p>
        </div>
      ) : null}
    </div>
  )
}
