import { get } from 'react-hook-form'
import dayjs from 'dayjs'
import { DEBUG } from '../../constants'
import { questionnaireTypes, QuestionnaireTypes } from './QuestionnaireCRUD'

export const getQuestionnaireConfig = (
  isOnboarding: boolean,
  isGroup: boolean,
  recurrence,
  type: QuestionnaireTypes
) => {
  if (isOnboarding) {
    return questionnaireConfigBase.concat([])
  }

  if (isGroup) {
    const groupConfig: any[] = getGroupQuestionnaireConfig().concat([])
    return groupConfig
  }

  if (type === questionnaireTypes.TRIGGERED) {
    const oneTimeConfig: any[] = getTriggeredConfig(recurrence).concat([])
    return oneTimeConfig
  }

  const b: any[] = getNonOnboardingQuestionnaireConfig(recurrence).concat([])
  //const a = questionnaireConfigBase.concat(b)
  return b
}

export const getTranslateConfig = (isOnboarding: boolean) => {
  //
  // If onboarding is true, then only return the name
  // there is no notifications for onboarding
  //
  if (isOnboarding) {
    return translateConfig.filter((item) => item.id === 'name')
  }

  return translateConfig.concat([])
}

const questionnaireConfigBase = [
  {
    id: 'internalTitle',
    type: 'TEXT',
    placeholder: 'Add internal title',
    label: 'Internal Title',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Internal title is required',
  },
] as const

/**
 *
 * Eligibility and Standard questionnaire
 *
 *
 */
const getNonOnboardingQuestionnaireConfig = (recurrence) => {
  return [
    {
      id: 'internalTitle',
      type: 'TEXT',
      placeholder: 'Add internal title',
      label: 'Internal Title',
      prefix: '',
      postfix: '',
      value: '',
      validation: { required: true },
      errorMsg: 'Internal title is required',
    },
    {
      id: 'recurrence.type',
      type: 'DROPDOWN',
      placeholder: 'Select a recurrence type',
      label: 'Recurrence type',
      prefix: '',
      postfix: '',
      value: 'DAILY',
      options: [] /*recurrence.map((item) => {
        return { value: item, label: item }
      }),*/,
      validation: { required: true },
      errorMsg: 'A rounding must be selected',
      disabled: true,
    },

    {
      id: 'recurrence',
      type: 'WINDOW',
      label: 'Questionnaire window',
      postfix: '',
      value: {
        startAt: null,
        endAt: null,
      },
      validation: {
        required: true,
        validate: (_, formValues) => {
          const format = 'HH:mm'
          const startFormValue = get(formValues, 'recurrence.startAt')
          const endFormValue = get(formValues, 'recurrence.endAt')

          const startAt = dayjs(`${startFormValue.h}:${startFormValue.m}`, format)
          const endAt = dayjs(`${endFormValue.h}:${endFormValue.m}`, format)

          return !startAt.isSame(endAt) ? true : 'Cannot have the same start time as the end time'
        },
      },
      errorMsg: 'Start and end time missing',
    },
    {
      id: 'notifications.reminders.endTime',
      type: 'REMINDER_TIME',
      placeholder: '00:00',
      label: 'Schedule end reminder ',
      //label: 'End reminder time',
      postfix: '',
      value: null,
      validation: {
        required: true,
        validate: (value: { h: number; m: number; duration: number }, formValues) => {
          const format = 'HH:mm'
          const startFormValue = get(formValues, 'recurrence.startAt')
          const endFormValue = get(formValues, 'recurrence.endAt')

          if (!startFormValue || !endFormValue) return false

          const startAt = dayjs(`${startFormValue.h}:${startFormValue.m}`, format)
          const endAt = dayjs(`${endFormValue.h}:${endFormValue.m}`, format)

          const time = dayjs(`${value.h}:${value.m}`, format)
          return !time.isBefore(startAt) && !time.isAfter(endAt)
        },
      },
      errorMsg: 'End reminder time must be within the questionnaire window',
    },
  ] as const
}

const getTriggeredConfig = (recurrence) => {
  return [
    {
      id: 'recurrence.trigger',
      type: 'DROPDOWN',
      outputFormat: 'minutes',
      placeholder: 'select trigger',
      label: 'Trigger on:',
      postfix: '',
      value: 'PHASE_START',
      options: [
        { value: 'PHASE_START', label: 'On phase start' },
        { value: 'PHASE_END', label: 'On phase end' },
      ],

      validation: {
        required: true,
      },
      errorMsg: 'End reminder time must be within the questionnaire window',
    },

    {
      id: 'internalTitle',
      type: 'TEXT',
      placeholder: 'Add internal title',
      label: 'Internal Title',
      prefix: '',
      postfix: '',
      value: '',
      validation: { required: true },
      errorMsg: 'Internal title is required',
    },

    {
      id: 'recurrence.type',
      type: 'DROPDOWN',
      placeholder: 'Select a recurrence type',
      label: 'Recurrence type',
      prefix: '',
      postfix: '',
      value: recurrence[1],
      options: [{ value: recurrence[1], label: recurrence[1] }],
      validation: { required: true },
      errorMsg: 'Please select a recurrence type',
      disabled: true,
    },

    {
      id: 'recurrence.offset',
      type: 'DROPDOWN',
      placeholder: 'Select offset in days',
      label: 'Offset from trigger',
      postfix: '',
      value: null,
      options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((day) => {
        const str = day !== 1 ? ' days ' : ' day '
        return { value: day, label: day + str }
      }),
      validation: {
        required: 'Must have a valid time',
        validate: (value) => {
          console.log('questionnaireConfig > value = ', value)

          return value > -1
        },
      },
      errorMsg: 'End reminder time must be within the questionnaire window',
    },

    {
      id: 'recurrence.window',
      type: 'TIME',
      outputFormat: 'minutes',
      placeholder: '00:00',
      label: 'Questionnaire Window ',
      postfix: '( hours/min to answer )',
      value: null,
      validation: {
        required: 'Must have a valid time',
        validate: (value: { h: number; m: number; duration: number }, formValues) => {
          return true
        },
      },
      errorMsg: 'End reminder time must be within the questionnaire window',
    },

    {
      id: 'notifications.reminders.endTime',
      type: 'REMINDER_TIME',
      placeholder: '00:00',
      label: 'Schedule end reminder (time before end)',

      postfix: '',
      value: null,
      validation: {
        required: 'Must have a valid time',
        validate: (value: { h: number; m: number; duration: number }, formValues) => {
          const startFormValue = get(formValues, 'recurrence.window')
          if (!startFormValue) {
            return 'Questionnaire window must also have a valid time'
          }

          const inMin = value.h * 60 + value.m

          if (inMin > startFormValue - 15) {
            return 'End reminder time must be within the questionnaire window'
          }

          if (inMin < 15) {
            return 'End reminder should be further than 15 minutes from the start'
          }

          return true
        },
      },
      errorMsg: 'End reminder time is not valid',
    },
  ] as const
}

/**
 *
 * GROUP QUESTIONNAIRE
 * --------------------------------
 */
const getGroupQuestionnaireConfig = () => {
  return [
    {
      id: 'internalTitle',
      type: 'TEXT',
      placeholder: 'Add internal title',
      label: 'Internal Title',
      prefix: '',
      postfix: '',
      value: DEBUG ? 'An Internal title' : '',
      validation: { required: true },
      errorMsg: 'Internal title is required',
    },
    {
      id: 'recurrence.type',
      type: 'DROPDOWN',
      placeholder: 'Select a recurrence type',
      label: 'Recurrence type',
      prefix: '',
      postfix: '',
      value: 'NONE',
      options: [],
      validation: { required: true },
      errorMsg: 'A rounding must be selected',
      disabled: true,
    },

    {
      id: 'recurrence.window',
      type: 'TIME',
      outputFormat: 'minutes',
      placeholder: '00:00',
      label: 'Questionnaire Window ',
      postfix: '( hours/min to answer )',
      value: null,
      validation: {
        required: 'Must have a valid time',
      },
      errorMsg: 'End reminder time must be within the questionnaire window',
    },

    {
      id: 'recurrence.offset',
      type: 'TIME',
      outputFormat: 'minutes',
      placeholder: '00:00',
      label: 'Offset from activation',
      postfix: ' ( hours/min from activation )',
      value: null,
      validation: {
        required: 'Must have a valid time',
      },
      errorMsg: 'End reminder time must be within the questionnaire window',
    },

    {
      id: 'notifications.reminders.endTime',
      type: 'REMINDER_TIME',
      placeholder: '00:00',
      label: 'Schedule end reminder (time before end)',

      postfix: '',
      value: null,
      validation: {
        required: 'Must have a valid time',
        validate: (value: { h: number; m: number; duration: number }, formValues) => {
          const startFormValue = get(formValues, 'recurrence.window')
          if (!startFormValue) {
            return 'Questionnaire window must also have a valid time'
          }

          const inMin = value.h * 60 + value.m

          if (inMin > startFormValue - 15) {
            return 'End reminder time must be within the questionnaire window'
          }

          if (inMin < 15) {
            return 'End reminder should be further than 15 minutes from the start'
          }

          return true
        },
      },
      errorMsg: 'End reminder time is not valid',
    },
  ] as const
}

const translateConfig = [
  {
    id: 'name',
    type: 'TEXT_TRANSLATION',
    placeholder: 'Add title',
    label: 'Title',
    prefix: '',
    postfix: '(displayed to the user)',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },
  {
    id: 'notifications.reminders.start',
    type: 'TEXT_TRANSLATION',
    placeholder: 'Start reminder text',
    label: 'Start reminder message',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Reminder text is required',
  },
  {
    id: 'notifications.reminders.end',
    type: 'TEXT_TRANSLATION',
    placeholder: 'End reminder text',
    label: 'End reminder message',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Reminder text is required',
  },
]

export const phaseEligibilityConfig = [
  {
    id: 'eligibility.daysInStudy',
    type: 'NUMBER',
    placeholder: 'Min amount of days',
    label: 'Minimum days in study',
    prefix: 'Days:',
    postfix: '',
    value: 7,
    validation: { required: true },
    //className: 'col-12 ',
    errorMsg: 'A minimum amount of days must be selected',
    disabled: true,
  },
  {
    id: 'eligibility.days',
    type: 'NUMBER',
    placeholder: 'Amount of days to include',
    label: 'Calculate for days:',
    prefix: '',
    postfix: '',
    value: 7,
    validation: { required: true },
    //className: 'col-6 pe-1',
    errorMsg: 'Total amount of days must be selected',
    disabled: true,
  },

  {
    id: 'eligibility.minDays',
    type: 'NUMBER',
    placeholder: 'Min amount of days',
    label: 'Minimum prerequisites in days',
    prefix: 'Days:',
    postfix: '',
    value: 4,
    validation: { required: true },
    //className: 'col-12 ',
    errorMsg: 'A minimum amount of days must be selected',
    disabled: true,
  },

  {
    id: 'section',
    type: 'SECTION',
    placeholder: '',
    label: 'Average score',
    prefix: '',
    postfix: '(min/max)',
    value: '',
    validation: { required: true },
    //className: 'col-6 pe-1',
    errorMsg: '',
    disabled: true,
  },

  {
    id: 'eligibility.averageMin',
    type: 'NUMBER',
    placeholder: 'Average min',
    label: 'Average min',
    prefix: '',
    postfix: '',
    value: 4,
    validation: { required: true },
    className: 'col-5 ',
    errorMsg: 'A maximum average must be set',
    disabled: true,
  },

  {
    id: '',
    type: 'SPACER',
    placeholder: '',
    label: '',
    prefix: '',
    postfix: '',
    value: '>=',
    validation: { required: true },
    className: 'col-2 py-1 px-0',
    errorMsg: 'A maximum average must be set',
    disabled: true,
  },

  {
    id: 'eligibility.averageMax',
    type: 'NUMBER',
    placeholder: 'Average max',
    label: 'Average max',
    prefix: '',
    postfix: '',
    value: 4,
    validation: { required: true },
    className: 'col-5 ',
    errorMsg: 'A maximum average must be set',
    disabled: true,
  },

  {
    id: 'section',
    type: 'SECTION',
    placeholder: '',
    label: 'Standard deviation',
    prefix: '',
    postfix: '(min/max)',
    value: '',
    validation: { required: true },
    //className: 'col-6 pe-1',
    errorMsg: '',
    disabled: true,
  },
  {
    id: 'eligibility.deviationMin',
    type: 'NUMBER',
    placeholder: 'Deviation min',
    label: 'Deviation min',
    prefix: 'Days:',
    postfix: '',
    value: 4,
    validation: { required: true },
    className: 'col-5 ',
    errorMsg: 'A maximum deviation must be set',
    disabled: false,
  },
  {
    id: '',
    type: 'SPACER',
    placeholder: '',
    label: '',
    prefix: '',
    postfix: '',
    value: '>=',
    validation: { required: true },
    className: 'col-2 py-1 px-0',
    errorMsg: 'A maximum average must be set',
    disabled: true,
  },
  {
    id: 'eligibility.deviationMax',
    type: 'NUMBER',
    placeholder: 'Deviation max',
    label: 'Deviation max',
    prefix: '',
    postfix: '',
    value: 4,
    validation: { required: true },
    className: 'col-5 ',
    errorMsg: 'A maximum deviation must be set',
    disabled: false,
  },

  {
    id: 'eligibility.allowPartials',
    type: 'SWITCH',
    placeholder: '',
    label: 'Allow partials answers',
    prefix: '',
    postfix: '',
    value: false,
    validation: {
      validate: (value) => {
        return typeof value === 'boolean'
      },
    },
    className: '',
    errorMsg: 'A maximum deviation must be set',
    disabled: false,
  },

  {
    id: 'eligibility.rounding',
    type: 'DROPDOWN',
    placeholder: '',
    label: 'Rounding',
    prefix: '',
    postfix: '',
    value: '',
    options: [
      { value: 10, label: 'Nearest tenth - 0.1' },
      { value: 100, label: 'Nearest hundredth - 0.01' },
      { value: 1000, label: 'Nearest thousandth - 0.001' },
    ],
    validation: { required: true },
    errorMsg: 'A rounding must be selected',
    disabled: false,
  },
]
