import {
  DropdownFormConfigObject,
  LanguageISOCode,
  SupportedCountriesType,
  TextFormConfigObject,
  VIEW_TYPE,
} from '../../Types'
import { FormAutoComplete } from './FormAutoComplete'
import { FormDateRange } from './FormDateRange'
import { FormDropDown } from './FormDropDown'
import { FormDynamicOptions } from './FormDynamicOptions'
import { FormDynamicOptionsTranslation } from './FormDynamicOptionsTranslation'
import { FormInputMultiple } from './FormInputMultiple'
import { FormNumber } from './FormNumber'
import { FormPhoneNumber } from './FormPhoneNumber'
import { FormSelectMultiple } from './FormSelectMultiple'
import { FormSlider } from './FormSlider'
import { FormSliderRange } from './FormSliderRange'

import { FormTextArea } from './FormTextArea'
import { FormTextAreaTranslation } from './FormTextAreaTranslation'
import { FormTextField } from './FormTextField'
import { FormTextFieldTranslation } from './FormTextFieldTranslation'
import { FormTime } from './FormTime'
import { ReminderTimeWidget } from '../../pages/questionnaire/ReminderTimeWidget'
import React from 'react'
import { WindowWidget } from '../../pages/questionnaire/WindowWidget'
import { FormSwitch } from './FormSwitch'
import { FormViewItem } from './FormViewItem'

export const getFormType = (
  formConfigObj: TextFormConfigObject | DropdownFormConfigObject | any,
  index: number,
  formHook: any,
  languages?: any[],
  currentLanguage?: LanguageISOCode,
  supportedCountries: SupportedCountriesType[] = [],
  viewType: VIEW_TYPE = 'CREATE'
) => {
  const type = formConfigObj.type

  const copy = { ...formConfigObj }

  switch (type) {
    case 'TEXT':
      return <FormTextField key={index} viewType={viewType} data={copy} formHook={formHook}></FormTextField>

    case 'DROPDOWN':
      return <FormDropDown key={index + formConfigObj.value} data={formConfigObj} formHook={formHook}></FormDropDown>

    case 'SWITCH':
      return <FormSwitch key={index} data={formConfigObj} formHook={formHook}></FormSwitch>

    case 'NUMBER':
      return <FormNumber key={index} data={formConfigObj} formHook={formHook}></FormNumber>

    case 'SLIDER_RANGE':
      return <FormSliderRange key={index} data={formConfigObj} formHook={formHook}></FormSliderRange>

    case 'AUTOCOMPLETE':
      return <FormAutoComplete key={index} data={formConfigObj} formHook={formHook}></FormAutoComplete>

    case 'SLIDER':
      return <FormSlider key={index} data={formConfigObj} formHook={formHook}></FormSlider>

    case 'SELECT_MULTIPLE':
      return <FormSelectMultiple key={index} data={formConfigObj} formHook={formHook}></FormSelectMultiple>

    case 'PHONE':
      return (
        <FormPhoneNumber
          key={index}
          data={formConfigObj}
          formHook={formHook}
          viewType={viewType}
          supportedCountries={supportedCountries}
        ></FormPhoneNumber>
      )

    case 'TIME':
      return <FormTime key={index} data={formConfigObj} formHook={formHook}></FormTime>

    case 'DATE_RANGE':
      return <FormDateRange key={index} data={formConfigObj} formHook={formHook} />

    case 'TEXTAREA':
      return <FormTextArea key={index} data={formConfigObj} formHook={formHook}></FormTextArea>

    case 'DYNAMIC_OPTIONS':
      return <FormDynamicOptions key={index} data={formConfigObj} formHook={formHook}></FormDynamicOptions>

    case 'INPUT_MULTIPLE':
      return <FormInputMultiple key={index} data={formConfigObj} formHook={formHook}></FormInputMultiple>

    case 'TEXT_TRANSLATION':
      return (
        <FormTextFieldTranslation
          key={index}
          data={copy}
          formHook={formHook}
          languages={languages}
          currentLanguage={currentLanguage}
        />
      )

    case 'TEXTAREA_TRANSLATION':
      return (
        <FormTextAreaTranslation
          key={index}
          data={formConfigObj}
          formHook={formHook}
          languages={languages}
          currentLanguage={currentLanguage}
        />
      )

    case 'DYNAMIC_OPTIONS_TRANSLATION':
      return (
        <FormDynamicOptionsTranslation
          key={index}
          data={formConfigObj}
          formHook={formHook}
          languages={languages}
          currentLanguage={currentLanguage}
        />
      )

    case 'REMINDER_TIME':
      return <ReminderTimeWidget key={index} data={formConfigObj} formHook={formHook} />

    case 'WINDOW':
      return <WindowWidget key={index} data={formConfigObj} formHook={formHook} />

    case 'SPACER':
      return <FormViewItem key={index} item={formConfigObj}></FormViewItem>

    case 'SECTION':
      return (
        <div key={index} className="row" style={{}}>
          <div className="col-12 mt-2">
            <p className="">
              {formConfigObj.label} <span className="opacity-75">{formConfigObj.postfix}</span>
            </p>
            {formConfigObj.value ? <p className="opacity-75 mb-2">{formConfigObj.value}</p> : null}
          </div>
        </div>
      )

    default:
      return (
        <div key={Math.random() * 10} className="">
          WHAT
        </div>
      )
  }
}
