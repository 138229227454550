/**
 * Created by @author Søren Tramm
 */
import { get, UseFormReturn } from 'react-hook-form'
import React, { useEffect } from 'react'
import { FormLabel } from '../../components/form/FormLabel'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import { ReminderTimeWidgetConfigObject } from '../../Types'

export const ReminderTimeWidget = ({
  data,
  formHook,
}: {
  data: ReminderTimeWidgetConfigObject
  formHook: UseFormReturn
}) => {
  const format = 'HH:mm'

  const {
    register,
    unregister,
    setValue,
    formState: { errors },
    clearErrors,
    getValues,
  } = formHook

  const errorState = get(errors, data.id)
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  // We need to the questionnaire's window time
  const questionnaireWindowStart: { h: number; m: number } = getValues('recurrence.startAt')
  const questionnaireWindowEnd: { h: number; m: number } = getValues('recurrence.endAt')

  const dayJs2Saved = (time: dayjs.Dayjs) => {
    return { h: time.get('h'), m: time.get('m'), duration: 3 } //{ h: date ? date.hour() : 0, m: date ? date.minute() : 0 }
  }

  // Register / unregister
  useEffect(() => {
    register(data.id, {
      required: data?.validation?.required,
      validate: data?.validation?.validate,
      value: data?.value,
    })
  }, [register, data])

  useEffect(() => {
    // We need to unregister explicitly
    // And we need to do it in a separate hook to avoid an endless render loop
    return () => {
      unregister(data.id)
    }
  }, [unregister, data.id])

  const getCurrentValue = () => {
    // Use values in the formhook if it exists
    if (getValues(data.id)) return dayjs(`${getValues(data.id).h}:${getValues(data.id).m}`, format)

    // Use saved values from the database if formhook value does not exist
    if (data.value) return dayjs(`${data.value.h}:${data.value.m}`, format) // Values are saved in Mongo

    // If here, no previous value has been set/saved and no value exists in the formhook
    // We are going to calculate a default value from the end-time of the questionnaire window
    // If the questionnaire window has not been set - leave. By returning undefined the time picker is being cleared
    if (!questionnaireWindowStart || !questionnaireWindowEnd) return undefined

    // Convert to dayjs format
    const start = dayjs(`${questionnaireWindowStart.h}:${questionnaireWindowStart.m}`, format)
    const end = dayjs(`${questionnaireWindowEnd.h}:${questionnaireWindowEnd.m}`, format)

    // If the difference between window start and end time is greater or equal to 1 hour, subtract 1 hour from the end time.
    // Else return the window start time
    const deltaTime = end.diff(start, 'h')
    const value = deltaTime >= 1 ? end.subtract(1, 'hour') : start

    // Save default value to formhook
    setValue(data.id, dayJs2Saved(value))

    return value
  }

  const onChangeHandler = (value: dayjs.Dayjs | null) => {
    clearErrors()
    if (value) {
      // Save selected value to formhook
      setValue(data.id, dayJs2Saved(value))
    }
  }

  /**
   * Limit the selectable hours to the values of the window's start/end
   * Limit the selectable minute values to 0, 15, 30, 45
   */
  const getDisabledTime = () => {
    return {
      // Disable selecting hours outside the questionnaire window
      disabledHours: () =>
        Array.from({ length: 24 }, (_, index) => index).filter(
          (h) => h < questionnaireWindowStart?.h || h > questionnaireWindowEnd?.h
        ),
      // Creates options for selecting quarters
      disabledMinutes: () =>
        Array.from({ length: 60 }, (_, index) => index).filter((m) => ![0, 15, 30, 45].includes(m % 60)),
    }
  }

  return (
    <div className="col-12 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p className="mb-1">
            {data.label} <span className="opacity-75 p-small ">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      <TimePicker
        value={getCurrentValue()}
        className="w-100"
        placeholder={data.placeholder}
        format={format}
        minuteStep={15}
        showNow={false}
        disabledTime={getDisabledTime}
        onChange={onChangeHandler}
        changeOnBlur={true}
        status={errorState ? 'error' : ''}
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
