/**
 * Created by @author Søren Tramm
 */
import React from 'react'
import { CRUD_VIEW_TYPE, HTTPMethod, VIEW_TYPE } from '../../Types'
import { useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { post } from '../../service/API'
import { FormLabel } from '../../components/form/FormLabel'
import { Button, Popconfirm } from 'antd'
import { useSWRConfig } from 'swr'
import { useByEndpoint } from '../../hooks/useByEndpoint'
import { openNotification } from '../../components/notifications/openNotification'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const ProtocolClone = ({ closeDrawer, onClose, viewType }: Props) => {
  const { mutate: mutateGlobal } = useSWRConfig()
  const { projectId, protocolId } = useParams() as { projectId: string; protocolId: string }
  const { data } = useByEndpoint(protocolId ? ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId) : '')

  //const navigate = useNavigate()

  const onSubmit = async () => {
    const endpoint = ENDPOINTS.PROTOCOLS.ACTIONS.CLONE(projectId)
    const sendData = { protocolId }

    try {
      await post(endpoint, sendData, HTTPMethod.POST)
      //const newProtocolId = res.data._id

      openNotification('Protocol cloned ', '', 'happy')
      //mutateGlobal(ENDPOINTS.PROTOCOLS.GET_ACTIVE_DRAFT(projectId))
      mutateGlobal(ENDPOINTS.PROTOCOLS.GET_ACTIVE_DRAFT(projectId))
      mutateGlobal(ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId))

      if (onClose) {
        onClose()
      }

      // navigate(`/project/${projectId}/protocol/${newProtocolId}/draft`)
      if (closeDrawer && viewType !== CRUD_VIEW_TYPE.VIEW) {
        //closeDrawer(`/project/${projectId}/protocol/${newProtocolId}/draft`)
        // @Soren - this can be passed from outside into the drawer. Not the CRUD
      }
    } catch (error: any) {
      console.log('error = ', error)
      openNotification('Cloning failed: ', error.message, 'sad')
    }
  }

  // console.log('ProtocolClone > data > ', data)
  //
  // const openNotification = (
  //     msg: string = 'Created',
  //     description: string = 'lorem ipsum...',
  //     smiley: 'happy' | 'sad' | '' = ''
  // ) => {
  //   notification.open({
  //     duration: 2.5,
  //     message: msg,
  //     description: description,
  //     icon: smiley === 'happy' ? <SmileOutlined style={{ color: '#108ee9' }} /> : null,
  //   })
  // }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }
  return (
    <>
      <div className="">
        <div className="col-12 mb-3">
          <FormLabel label="Clone protocol:"></FormLabel>
          <h6 className="fw-bold">{data?.title}</h6>
        </div>

        <div className="col-12 mt-3">
          All content (phases, visits, questionnaires and questions) will be copied and added to the new protocol.
        </div>

        <div className="col-12">
          <hr />
        </div>

        <div className="col-12 d-flex justify-content-end">
          <div>
            <Button className="me-2" type="default" onClick={navigateBack}>
              Cancel
            </Button>
            <Popconfirm
              placement="left"
              title={<p className="pe-4">Please confirm your action</p>}
              description={<p className="pe-4">Do you want to continue with cloning this protocol</p>}
              onConfirm={onSubmit}
              //onCancel={}
              okText={<p className="px-2">Yes</p>}
              cancelText="No"
            >
              <Button type="primary">Save Clone</Button>
            </Popconfirm>
          </div>
        </div>
      </div>
    </>
  )
}
