/**
 * Created by @author Søren Tramm
 */
import { get, UseFormReturn } from 'react-hook-form'
import React, { useEffect } from 'react'
import { FormLabel } from '../../components/form/FormLabel'
import { TimePicker } from 'antd'
import { StoreDateTimeType } from '../../util/datetime'
import dayjs from 'dayjs'
import { WindowWidgetConfigObject } from '../../Types'

export const WindowWidget = ({ data, formHook }: { data: WindowWidgetConfigObject; formHook: UseFormReturn }) => {
  const startAtId = data.id + '.startAt' // FIXME @søren - maybe this should be configurable
  const endAtId = data.id + '.endAt' // FIXME @søren - maybe this should be configurable
  const format = 'HH:mm' // FIXME this should be configurable

  const {
    register,
    unregister,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = formHook

  const currentValueStart: { h: number; m: number } = getValues(startAtId) ? getValues(startAtId) : data.value.startAt
  const currentValueEnd: { h: number; m: number } = getValues(endAtId) ? getValues(endAtId) : data.value.endAt

  const errorState = get(errors, startAtId) || get(errors, endAtId)
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  useEffect(() => {
    console.log('WindowWidget > run = ')
    register(startAtId, {
      required: data?.validation?.required,
      validate: data?.validation?.validate,
      value: data?.value.startAt,
    })
    register(endAtId, {
      required: data?.validation?.required,
      validate: data?.validation?.validate,
      value: data?.value.endAt,
    })
  }, [register, startAtId, endAtId, data])

  useEffect(() => {
    // We need to unregister explicitly
    // And we need to do it in a separate hook to avoid an endless render loop
    return () => {
      unregister(startAtId)
      unregister(endAtId)
    }
  }, [unregister, startAtId, endAtId])

  const saved2DayJs = (value: { h: number; m: number }): dayjs.Dayjs => {
    return dayjs(`${value.h}:${value.m}`, format)
  }

  const dayJs2Saved = (date: dayjs.Dayjs): StoreDateTimeType => {
    console.log('WindowWidget > date?.minute() = ', date?.minute())

    return { h: date?.hour() || 0, m: date?.minute() || 0 }
  }

  const changeTimeRangeHandler = (value) => {
    clearErrors()

    console.log('WindowWidget > startAt = ', value)
    if (value) {
      const [startAt, endAt] = value

      setValue(startAtId, dayJs2Saved(startAt))
      setValue(endAtId, dayJs2Saved(endAt))
    } else {
      setValue(startAtId, undefined)
      setValue(endAtId, undefined)
    }
  }

  return (
    <div className="col-12 w-100 py-2">
      <div className="col-12">
        <FormLabel label={data.label}>
          {data.postfix ? (
            <p>
              {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
            </p>
          ) : null}
        </FormLabel>
        <TimePicker.RangePicker
          value={
            currentValueStart && currentValueEnd
              ? [saved2DayJs(currentValueStart), saved2DayJs(currentValueEnd)]
              : undefined
          }
          status={errorState ? 'error' : undefined}
          className="w-100"
          format={format}
          minuteStep={1}
          changeOnBlur={true}
          onChange={changeTimeRangeHandler}
        />
        {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
      </div>
    </div>
  )
}
